#page-top {

	.mv {
		width: 100%;
		height: 600px;
		max-width: 1600px;
		position: relative;
		margin: 0 auto;

		.logo {
			position: absolute;
			top: 40px;
			left: 40px;
			width: 150px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.profile {
		width: 800px;
		margin: 60px auto 0;

		table {
			width: 100%;
			border-top: 1px solid #ccc;
			text-align: left;

			tr {
				width: 100%;
				border-bottom: 1px solid #ccc;
			}

			th {
				width: 200px;
				padding: 20px 0;
			}

			td {
				padding: 20px -0;
			}
		}
	}

	.map {
		margin-top: 60px;
		width: 100%;

		iframe {
			width: 100%;
			vertical-align: top;
		}
	}

	.copyright {
		width: 100%;
		padding: 10px 0;
		background: #1F2C65;
		color: $color-white;
		font-size: 13px;
		text-align: center;
		margin-top: 60px;
	}
}

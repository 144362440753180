
// LINK ---------------------------------------------
@mixin txt_link_opacity($deco:none, $color:$default-color, $color2:$default-color, $hover-deco:none) {
	a,
	a:link,
	a:visited {
		color: $color;
		text-decoration: $deco;
		@include transition(.2);
	}

	@include min_screen($break-point + 1px) {
		a:hover {
			opacity: .7;
			text-decoration: $hover-deco;
		}
	}
}

@mixin txt_link_color($deco:none, $color:$default-color, $color2:$color-green, $hover-deco:none) {
	a,
	a:link,
	a:visited {
		color: $color;
		text-decoration: $deco;
		@include transition(.2);
	}


	@include min_screen($break-point + 1px) {
		a:hover {
			color: $color2;
			text-decoration: $hover-deco;
		}
	}
}



// CLEAR FIX ---------------------------------------------
@mixin cf {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}


// ANIMATION ---------------------------------------------
@mixin transition($time:.2, $prop:all, $easing:ease-in-out) {
	transition: $prop #{$time}s $easing;
}


// IMEDIA QUERY ---------------------------------------------
@mixin min_screen($bp) {
	@media screen and (min-width: $bp) {
		@content;
	}
}

@mixin max_screen($bp) {
	@media screen and (max-width: $bp) {
		@content;
	}
}

// IE & EDGE ---------------------------------------------
@mixin eg() {
	@supports (-ms-ime-align:auto) {
		@content;
	}
}

@mixin ie() {
	@media all and (-ms-high-contrast:none) {
		@content;
	}
}

body {
	font-family: $font-jp;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
	color: $default-color;
}

img{
	max-width: 100%;
}

img[src$=".svg"] {
	width: 100%;
	height: auto;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    font-weight: normal;
}
/* pc-sp */
.switch--sp {
  display: none !important;
  @include max_screen($break-point) {
    display: block !important;
    margin: 0 auto;
  }
}

.switch--pc {
  display: block !important;
  margin: 0 auto;
  @include max_screen($break-point) {
    display: none !important;
  }
}
